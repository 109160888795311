import React, { ReactNode, useCallback, useMemo, useState } from "react";
import Select, { OptionTypeBase, Styles } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Components } from "react-select/src/components";
import { Option } from "react-select/src/filters";
import { ActionMeta, MenuPlacement, MenuPosition, ValueType } from "react-select/src/types";
import classNames from "classnames";
import { isObject } from "lodash-es";
import { useAppSelector } from "@app/store/hooks";
import { getTheme } from "@app/store/userPreferences/userPreferences.selector";
import { APP_THEME } from "@common/constants/application.constants";
import { useScreenDensity } from "@common/hooks/useScreenDensity";
import { useTypeaheadSearch } from "@common/hooks/useTypeaheadSearch/useTypeaheadSearch";
import { getTypeaheadOptions, mergeStyles } from "./select.helpers";
import { useSelectComponents } from "./useSelectComponents";
import "./select.less";

export const COLORS = {
    YELLOW: "#FFDA6A",
    BLACK100: "#202020",
    BLACK96: "#292929",
    BLACK92: "#313131",
    WHITE: "#fff",
    BLUE: "#4668bf",
    MID_GREY: "#707070",
    LIGHT_GREY: "#f5f5f5",
};

const SELECT_LIGHT_STYLES = {
    control: styles => ({
        ...styles,
        backgroundColor: "white",
        width: "100%",
        borderBottom: `1px solid ${COLORS.BLUE}`,
        minHeight: "26px",
        maxHeight: "120px",
        ":hover": {
            borderColor: "#0000aa",
        },
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: "0 8px",
        color: COLORS.YELLOW,
        ":hover": {
            borderColor: COLORS.YELLOW,
        },
    }),
    menuPortal: styles => ({
        ...styles,
        zIndex: 9999,
    }),
    menu: styles => ({
        ...styles,
        margin: 0,
    }),
    option: styles => ({
        ...styles,
        color: "#ff6e05",
    }),
    multiValueRemove: styles => ({
        ...styles,
        ":hover": {
            color: "white",
        },
    }),
} as Styles<OptionTypeBase, boolean>;

export const SELECT_DARK_STYLES = {
    control: (styles, state) => {
        const commonStyles = {
            ...styles,
            backgroundColor: state.isDisabled ? COLORS.MID_GREY : COLORS.BLACK100,
            border: "none",
            minHeight: "26px",
            maxHeight: state.selectProps.height || "125px",
            overflow: state.selectProps.height ? "hidden" : "auto",
            borderRadius: 0,
            width: "100%",
            borderBottom: `1px solid ${COLORS.WHITE}`,
            boxShadow: "none",
            outline:
                state.isFocused || state.selectProps.invalid
                    ? "2px solid rgba(255, 218, 106, 0.6)"
                    : 0,
            transition: "none",
            ":hover": {
                borderColor: COLORS.WHITE,
            },
        };

        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                minHeight: "16px",
            };
        }

        if (state.selectProps.screenDensity >= 2) {
            return {
                ...commonStyles,
                minHeight: "32px",
            };
        }

        return commonStyles;
    },
    dropdownIndicator: (styles, state) => {
        const commonStyles = {
            ...styles,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: COLORS.YELLOW,
            ":hover": {
                borderColor: COLORS.YELLOW,
            },
        };

        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                padding: "0",
                height: "15px",
                width: "15px",
            };
        }

        if (state.selectProps.screenDensity >= 2) {
            return {
                ...commonStyles,
                padding: "0",
                height: "30px",
                width: "24px",
            };
        }

        return {
            ...commonStyles,
            padding: state.selectProps.height <= 30 ? "4px" : "8px",
            fontSize: "20px",
        };
    },
    menuPortal: styles => ({
        ...styles,
        zIndex: 9999,
    }),
    menu: styles => ({
        ...styles,
        margin: 0,
        background: COLORS.BLACK100,
    }),
    input: (styles, state) => {
        return {
            ...styles,
            color: COLORS.WHITE,
            margin: (state as any).selectProps.screenDensity >= 4 ? "0" : "2px",
        };
    },
    indicatorsContainer: (styles, state) => ({
        ...styles,
        alignItems: "start",
    }),
    valueContainer: (styles, state) => ({
        ...styles,
        padding: state.selectProps.screenDensity >= 4 ? "1px 6px" : "2px 12px",
    }),
    singleValue: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? COLORS.LIGHT_GREY : COLORS.WHITE,
    }),
    multiValueLabel: (styles, state) => ({
        ...styles,
        padding: state.data.hideRemoveButton ? "3px 6px" : "3px",
    }),
    placeholder: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? COLORS.LIGHT_GREY : COLORS.YELLOW,
    }),
    clearIndicator: styles => ({
        ...styles,
        color: COLORS.YELLOW,
    }),
    indicatorSeparator: (styles, state) => {
        const commonStyles = {
            ...styles,
            backgroundColor: COLORS.YELLOW,
        };
        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                marginTop: "2px",
                marginBottom: "2px",
                width: "1px",
            };
        }
        if (state.selectProps.screenDensity >= 2) {
            return {
                ...commonStyles,
                marginTop: "6px",
                marginBottom: "6px",
                width: "1px",
            };
        }
        return {
            ...commonStyles,
            width: "2px",
        };
    },
    menuList: (styles, state) => {
        const commonStyles = {
            ...styles,
            background: COLORS.BLACK100,
        };

        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                maxHeight: "100px",
            };
        }

        if (state.selectProps.screenDensity >= 2) {
            return {
                ...commonStyles,
                maxHeight: "200px",
            };
        }

        return commonStyles;
    },
    option: (styles, state) => {
        const selected = state.isSelected
            ? {
                  background: COLORS.BLACK100,
                  color: COLORS.YELLOW,
                  ":before": {
                      content: "'•'",
                      marginRight: "6px",
                  },
              }
            : {};
        const focused = state.isFocused
            ? {
                  color: COLORS.BLACK92,
                  background: COLORS.YELLOW,
              }
            : {};
        const disabled = state.isDisabled
            ? {
                  color: COLORS.LIGHT_GREY,
                  background: COLORS.MID_GREY,
              }
            : {};

        const commonStyles = {
            ...styles,
            color: COLORS.WHITE,
            ":hover": {
                color: COLORS.BLACK92,
                background: COLORS.YELLOW,
            },
            ...disabled,
            ...selected,
            ...focused,
        };

        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                padding: "2px 6px",
                fontSize: "6.5px",
            };
        }

        return commonStyles;
    },
    multiValue: (styles, state) => {
        const commonStyles = {
            ...styles,
            backgroundColor: COLORS.YELLOW,
            color: COLORS.BLACK100,
        };

        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                height: "12px",
                margin: "1px",
            };
        }

        return commonStyles;
    },
    multiValueRemove: (styles, state) => {
        const commonStyles = {
            ...styles,
            ":hover": {
                color: COLORS.WHITE,
            },
        };

        if (state.selectProps.screenDensity >= 4) {
            return {
                ...commonStyles,
                paddingLeft: 0,
                paddingRight: 0,
                width: "10px",
            };
        }

        return commonStyles;
    },
    groupHeading: styles => ({
        ...styles,
        padding: "0 12px 8px 12px",
        color: COLORS.WHITE,
        fontSize: "inherit",
        fontWeight: 400,
        textTransform: "capitalize",
        borderBottom: `1px solid ${COLORS.MID_GREY}`,
    }),
} as Styles<OptionTypeBase, boolean>;

const INITIAL_STATE = {
    inputValue: "",
};

type TRegularOption = {
    label: string;
    value: string;
    [key: string]: unknown;
};

type TProps<TOption extends OptionTypeBase, TIsMulti extends boolean = false> = {
    value: ValueType<TOption, TIsMulti> | string | number;
    options: TOption[];
    onChange: (value: ValueType<TOption, TIsMulti>, actionMeta: ActionMeta<TOption>) => void;
    id?: string;
    testid?: string;
    className?: string;
    placeholder?: string;
    theme?: "dark" | "light";
    required?: boolean;
    invalid?: boolean;
    styles?: object;
    shouldMergeStyles?: boolean;
    customComponents?: Record<string, Components[keyof Components]>;
    menuPosition?: MenuPosition;
    menuPlacement?: MenuPlacement;
    disabled?: boolean;
    disablePortal?: boolean;
    isMulti?: TIsMulti;
    isSearchable?: boolean;
    isOptionsLimited?: boolean;
    isCreatableSelect?: boolean;
    isClearable?: boolean;
    height?: number;
    itemsLimit?: number;
    getOptionLabel?: (option: TOption) => string;
    getOptionValue?: (option: TOption) => any;
    onInputChange?: (input: string) => void;
    formatOptionLabel?: (option: TOption) => ReactNode;
    menuShouldBlockScroll?: boolean;
    hideSelectedOptions?: boolean;
    filterOption?: (option: Option, rawInput: string) => boolean;
};

export const StlSelect = <TOption extends OptionTypeBase, TIsMulti extends boolean = false>({
    id,
    className,
    theme,
    styles = {},
    shouldMergeStyles = false,
    customComponents,
    options,
    value,
    menuPosition = "absolute",
    menuPlacement = "auto",
    disabled,
    disablePortal,
    isMulti,
    isOptionsLimited,
    isCreatableSelect,
    height,
    itemsLimit,
    getOptionLabel = option => (option as unknown as TRegularOption).label,
    getOptionValue = option => (option as unknown as TRegularOption).value,
    onInputChange = () => {},
    ...restProps
}: TProps<TOption, TIsMulti>): JSX.Element => {
    const appTheme = useAppSelector(getTheme) || APP_THEME.DARK;

    if (!theme) {
        // eslint-disable-next-line no-param-reassign
        theme = appTheme;
    }

    const [inputValue, setInputValue] = useState(INITIAL_STATE.inputValue);

    const screenDensity = useScreenDensity();

    const _styles = useMemo(() => {
        if (shouldMergeStyles) {
            return mergeStyles(
                theme === "light" ? SELECT_LIGHT_STYLES : SELECT_DARK_STYLES,
                styles,
            );
        } else {
            return {
                ...(theme === "light" ? SELECT_LIGHT_STYLES : SELECT_DARK_STYLES),
                ...styles,
            };
        }
    }, [theme, styles, shouldMergeStyles]);

    const flatOptions = useMemo(
        () =>
            (options || []).reduce((res, option) => {
                if (option.options) {
                    return [...res, ...option.options];
                }
                return [...res, option];
            }, [] as Array<TOption>),
        [options],
    );

    const getValue = useCallback(
        _value => {
            if (
                _value !== "" &&
                _value !== undefined &&
                (!isObject(_value) || Array.isArray(_value))
            ) {
                return flatOptions.find(option => getOptionValue(option as TOption) === _value);
            }

            return _value;
        },
        [flatOptions, getOptionValue],
    );

    const parsedValue = useMemo(() => {
        if (isMulti) {
            return Array.isArray(value) && value.map(getValue);
        }

        return getValue(value);
    }, [value, getValue, isMulti]);

    const components = useSelectComponents(restProps, customComponents);

    const _options = useTypeaheadSearch({
        items: useMemo(
            () =>
                getTypeaheadOptions<TOption, TIsMulti>({
                    options,
                    value: parsedValue,
                    isMulti,
                    isOptionsLimited,
                    getOptionValue,
                }),
            [options, parsedValue, isMulti, isOptionsLimited, getOptionValue],
        ),
        searchToken: inputValue,
        limit: itemsLimit,
        getItemLabel: getOptionLabel,
        isLimited: isOptionsLimited,
    } as any);

    const handleInputChange = (_inputValue: string) => {
        setInputValue(_inputValue);
        onInputChange(_inputValue);
    };

    const SelectComponent: typeof React.Component = isCreatableSelect ? CreatableSelect : Select;

    return (
        <SelectComponent
            options={_options}
            components={components}
            className={classNames("stl-select", theme, className)}
            menuPortalTarget={!disablePortal && document.body}
            menuPosition={menuPosition}
            menuPlacement={menuPlacement}
            isDisabled={disabled}
            height={height}
            value={parsedValue}
            menuShouldBlockScroll
            captureMenuScroll={false}
            styles={_styles}
            inputId={id}
            isMulti={isMulti}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onInputChange={handleInputChange}
            screenDensity={screenDensity}
            {...restProps}
        />
    );
};

StlSelect.displayName = "StlSelect";
