import React from "react";
import { useScreenDensity } from "@common/hooks/useScreenDensity";

interface WithScreenDensityProps {
    screenDensity: number;
}

export const withScreenDensity = <T extends WithScreenDensityProps>(
    WrappedComponent: React.ComponentType<T>,
) => {
    return (props: Omit<T, keyof WithScreenDensityProps>) => {
        const screenDensity = useScreenDensity();
        return <WrappedComponent {...(props as T)} screenDensity={screenDensity} />;
    };
};
