import { useEffect, useState } from "react";

export const useScreenDensity = () => {
    const [density, setDensity] = useState<number>(window.devicePixelRatio);

    useEffect(() => {
        const updateDensity = () => {
            setDensity(window.devicePixelRatio);
        };

        window.addEventListener("resize", updateDensity);
        window.addEventListener("zoom", updateDensity);

        return () => {
            window.removeEventListener("resize", updateDensity);
            window.removeEventListener("zoom", updateDensity);
        };
    }, []);

    return density;
};
