import { ForwardedRef, forwardRef, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import type { IBigBangRoute } from "@app/bigBang/layout/bigBangLayout.routing";
import { SubscriptionModal } from "@app/bigBang/layout/navigation/subscriptionModal/subscriptionModal";
import { getUserInfo } from "@app/store/currentUser/currentUser.selector";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { setNavOpen } from "@app/store/userPreferences/userPreferences.actions";
import { StlPopover } from "@common/components";
import { ORG_COLORS_LIST } from "@common/constants/color.constants";
import { useMenuFocus } from "@common/hooks/useMenuFocus";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faChevronLeft,
    faChevronRight,
    faGear,
    faUserAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./navigation.less";

const ALPHABET_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXUZ";

const SubscriptionOption = ({
    onClick,
    isNavCollapsed,
}: {
    onClick: () => void;
    isNavCollapsed: boolean;
}) => {
    const subscriptionOption = (
        <div className="subscription-option" onClick={onClick} role="none">
            <div className="nav-option-wrapper">
                <div className="nav-icon-wrapper">
                    <FontAwesomeIcon className="nav-icon" icon={faGear} />
                </div>
                <span className="nav-option">Subscription & Settings</span>
            </div>
        </div>
    );

    return isNavCollapsed ? (
        <StlPopover
            className="nav-link-popover"
            trigger={["hover"]}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            sourceControl={subscriptionOption}
        >
            Subscription & Settings
        </StlPopover>
    ) : (
        subscriptionOption
    );
};

const NavOption = forwardRef(
    (
        {
            path,
            optionText,
            icon,
            isSelected,
            isNavCollapsed,
        }: {
            path: string;
            optionText: string;
            icon?: IconProp;
            isSelected: boolean;
            isNavCollapsed: boolean;
        },
        ref: ForwardedRef<HTMLAnchorElement>,
    ) => {
        const navOption = (
            <NavLink
                to={path}
                className={`navigation-link ${optionText.toLowerCase()}`}
                aria-label={`${isSelected ? "Current Page:" : ""}   ${optionText}`}
                ref={ref}
            >
                <div className="nav-option-wrapper">
                    <div className="nav-icon-wrapper">
                        {icon && <FontAwesomeIcon className="nav-icon" icon={icon} />}
                    </div>
                    <span className="nav-option">{optionText}</span>
                </div>
            </NavLink>
        );
        return (
            <li role="tab">
                {isNavCollapsed ? (
                    <StlPopover
                        className="nav-link-popover"
                        trigger={["hover"]}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        sourceControl={navOption}
                    >
                        {optionText}
                    </StlPopover>
                ) : (
                    navOption
                )}
            </li>
        );
    },
);

export const StlNavigation = ({
    tabList,
    isNavigationMenuOpen,
}: {
    tabList: Array<IBigBangRoute>;
    isNavigationMenuOpen: boolean;
}) => {
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const { org, subscription } = useAppSelector(getUserInfo);

    const expirationDate = subscription?.expiration_date;

    const dispatch = useAppDispatch();

    const { activeOptionIndex, tabRefs, handleKeyDown, handleClick } = useMenuFocus({
        tabs: tabList,
        isRouteBased: true,
    });

    const handleOpen = () => dispatch(setNavOpen(!isNavigationMenuOpen));

    const { abbreviation, color } = useMemo(() => {
        const data = org.org_name.split(" ").reduce(
            (
                result: {
                    abb: string;
                    sum: number;
                },
                str: string,
                index: number,
            ) => {
                if (!str || index > 3 || !!Number(str)) return result;

                const char = str[0].toUpperCase();

                return {
                    abb: result.abb + char,
                    sum: result.sum + ALPHABET_CHARS.indexOf(char),
                };
            },
            {
                abb: "",
                sum: 0,
            },
        );

        const colorIndex = data.sum % ORG_COLORS_LIST.length;

        return {
            abbreviation: data.abb,
            color: ORG_COLORS_LIST[colorIndex],
        };
    }, [org.org_name]);

    const options = useMemo(
        () =>
            tabList.map(({ path, icon, optionText }, index) => (
                <NavOption
                    key={path}
                    path={path}
                    optionText={optionText}
                    icon={icon}
                    isSelected={activeOptionIndex === index}
                    ref={node => {
                        if (!node) return;
                        tabRefs.current[index] = node;
                    }}
                    isNavCollapsed={!isNavigationMenuOpen}
                />
            )),
        [isNavigationMenuOpen, tabList, activeOptionIndex, tabRefs],
    );

    const accountInfo = useMemo(() => {
        return (
            <>
                <div className="account-logo" style={{ backgroundColor: color }}>
                    {abbreviation}
                </div>
                {isNavigationMenuOpen && (
                    <div className="account-info-wrapper">
                        <div className="account-name">{org.org_name}</div>
                        <div className="account-count">
                            <FontAwesomeIcon icon={faUserAlt} />
                            <span className="count">{org.active_user_count} users</span>
                        </div>
                        <div>Subscription Expires {expirationDate}</div>
                    </div>
                )}
            </>
        );
    }, [
        abbreviation,
        color,
        isNavigationMenuOpen,
        org.org_name,
        org.active_user_count,
        expirationDate,
    ]);

    const mountSubscriptionModal = () => (
        <SubscriptionModal
            isOpen={isSubscriptionModalOpen}
            close={() => setIsSubscriptionModalOpen(false)}
        />
    );

    return (
        <nav
            className={classNames("stl-navigation", {
                "navigation-open": isNavigationMenuOpen,
                "navigation-close": !isNavigationMenuOpen,
            })}
            role="navigation"
            aria-label="main navigation"
        >
            <div className="nav-menu">
                {accountInfo}
                <div
                    className="nav-links"
                    aria-labelledby="navigationHeader"
                    role="presentation"
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                >
                    <h3 className="sr-only" id="navigationHeader">
                        Main Navigation
                    </h3>
                    <ul className="nav-list" role="tablist">
                        {options}
                    </ul>

                    <SubscriptionOption
                        onClick={() => setIsSubscriptionModalOpen(true)}
                        isNavCollapsed={!isNavigationMenuOpen}
                    />
                </div>
            </div>
            <div className="nav-buttons">
                <button
                    type="button"
                    className="nav-button"
                    aria-label="Open or Close Menu"
                    aria-expanded={isNavigationMenuOpen}
                    onClick={() => handleOpen()}
                >
                    <FontAwesomeIcon
                        icon={isNavigationMenuOpen ? faChevronLeft : faChevronRight}
                    />
                </button>
            </div>
            {mountSubscriptionModal()}
        </nav>
    );
};
