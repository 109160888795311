import React, { Component, MutableRefObject, ReactNode, RefObject } from "react";
import { FormattedMessage } from "react-intl";
// @ts-ignore
import ReactTable from "react-table";
import {
    CellInfo,
    ComponentProps,
    DerivedDataObject,
    Filter,
    FinalState,
    Instance,
    RowInfo,
    SortingRule,
    TableProps,
} from "react-table-v6";
import classnames from "classnames";
import { StlButton } from "@common/components/button";
import { FilterHeader } from "@common/components/table/filterHeader";
import { TColumn, TColumnVisibility } from "@common/components/table/table.types";
import { getScrollBarWidth } from "@common/utils/scrollBarWidth";
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StlColumnSettings } from "../columnSettings";
import { FilterInfoBanner } from "./filterInfoBanner";
import { StlTablePageSize } from "./pageSizeOptions";
import { withScreenDensity } from "./withScreenDensity";
import "./table.less";
import "react-table/react-table.css";
import "./reactTableCustomizations";

const actionColumnId = "actions" as const;

const scrollBarWidth = getScrollBarWidth();

const DEFAULT_PAGE = 1 as const;
const DEFAULT_PAGE_OPTIONS = [25, 50, 100];
const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_OPTIONS[0];

const Next: ComponentProps["NextComponent"] = props => {
    return (
        <StlButton
            testid="nextPageBtn"
            variant="naked"
            size="lg"
            endIcon={<FontAwesomeIcon icon={faCaretRight} className="icon" />}
            {...props}
        >
            <FormattedMessage
                id="app.nextPage"
                defaultMessage="Next Page"
                description="Next Page"
            />
        </StlButton>
    );
};

const Previous: ComponentProps["PreviousComponent"] = props => {
    return (
        <StlButton
            variant="naked"
            size="lg"
            startIcon={<FontAwesomeIcon icon={faCaretLeft} className="icon" />}
            {...props}
        >
            <FormattedMessage
                id="app.previousPage"
                defaultMessage="Previous Page"
                description="Previous Page"
            />
        </StlButton>
    );
};

type TProps = Partial<
    Omit<TableProps, "columns" | "filtered" | "onSortedChange" | "onFilteredChange">
> & {
    columns: Array<TColumn>;
    screenDensity: number;
    filtered?: Array<Filter>;
    columnDefs?: Array<TColumn>;
    totalCount?: number;
    showFilterInfo?: boolean;
    clearHover?: () => void;
    hideFilters?: () => void;
    autoApplyFilter?: boolean;
    columnActionWidth?: number;
    onApplyFilter?: () => void;
    showColumnSettings?: boolean;
    clearAllFilters?: () => void;
    toolbarComponent?: ReactNode;
    mandatoryColumns?: Array<string>;
    reactTable?: RefObject<Instance | null>;
    columnVisibility?: TColumnVisibility;
    actionCell?: (cell: CellInfo) => ReactNode;
    onRowClick?: (row: RowInfo["row"]) => void;
    onRowHover?: (row: RowInfo["row"]) => void;
    isRowHighlighted?: (row: RowInfo["row"]) => boolean;
    onSortedChange?: (sort: Array<SortingRule>) => void;
    onSetColumns?: (columnVisibility: TColumnVisibility) => void;
    getSortedData?: (sortedData: Array<DerivedDataObject>) => void;
    onFilteredChange?: (filtered: Array<Filter>, column: TColumn) => void;
    tableRef?: MutableRefObject<HTMLDivElement | undefined>;
};

class _StlTable extends Component<TProps> {
    state = {
        filtered: [],
        sortingNotification: "",
    };

    componentDidMount() {
        if (this.props.tableRef) {
            this.props.tableRef.current = document.querySelector(".rt-tbody") as HTMLDivElement;
        }

        if (!this.props.pageSize) return;

        if (!DEFAULT_PAGE_OPTIONS.includes(this.props.pageSize)) {
            this.props.onPageSizeChange?.(DEFAULT_PAGE_SIZE, DEFAULT_PAGE);
        }
    }

    notifySortChanged = ([sort]: Array<SortingRule>) => {
        const sortedColumn = this.props.columns.find(column => column.id === sort.id);
        const sortDirection = sort.desc ? "descending" : "ascending";

        this.setState({
            sortingNotification: `Table is now sorted by ${sortedColumn?.label}, ${sortDirection}`,
        });
    };

    onSortedChange = (sort: Array<SortingRule>) => {
        if (this.props.onSortedChange) {
            this.props.onSortedChange(sort);
        }
        this.notifySortChanged(sort);
    };

    scrollTop = () => {
        document.querySelector(".rt-table")!.scrollTop = 0;
    };

    onPageChange = (pageIndex: number) => {
        this.scrollTop();
        this.props.onPageChange?.(pageIndex);
    };

    onFilteredChange = (filtered: Array<Filter>, column: TColumn) => {
        this.scrollTop();
        this.setState({ filtered });
        this.props.onFilteredChange?.(filtered, column);
    };

    clearAllFilters = () => this.setState({ filtered: [] });

    getAdjustedColumnWidth = (width?: number) => {
        const { screenDensity } = this.props;

        if (!width) return width;

        if (screenDensity >= 4) {
            return width / (screenDensity / 2);
        } else if (screenDensity >= 2) {
            return width / (screenDensity / 1.5);
        } else {
            return width;
        }
    };

    createTableColumns(columns: Array<TColumn>) {
        const { columnActionWidth = 150, actionCell, autoApplyFilter, onApplyFilter } = this.props;

        const tableColumns: Array<TColumn> = columns.map(column => ({
            ...column,
            minWidth: this.getAdjustedColumnWidth(column.minWidth),
            maxWidth: this.getAdjustedColumnWidth(column.maxWidth),
            width: this.getAdjustedColumnWidth(column.width),
            headerClassName: column.headerClassName || "stl-table-header",
        }));

        const commonSettingsForActionsColumn = {
            headerClassName: "stl-table-header stl-table-header-actions",
            filterable: true,
            sortable: false,
        };

        if (!!actionCell && !tableColumns.find(col => "Actions" === col.Header)) {
            tableColumns.splice(columns.length, 0, {
                ...commonSettingsForActionsColumn,
                id: actionColumnId,
                width: this.getAdjustedColumnWidth(columnActionWidth),
                className: "stl-table-actions",
                Header: <span className="menu-title">Actions</span>,
                Filter: () => <div />,
                Cell: actionCell,
            });
        }

        if (!autoApplyFilter && !!onApplyFilter) {
            const filterColumnIndex = tableColumns.findIndex(col => col.id === "tableFilter");

            if (filterColumnIndex !== -1) {
                tableColumns.splice(filterColumnIndex, 1);
            }

            tableColumns.push({
                ...commonSettingsForActionsColumn,
                width: this.getAdjustedColumnWidth(100),
                hidable: false,
                id: "tableFilter",
                Filter: () => <FilterHeader apply={onApplyFilter} />,
            });
        }

        return tableColumns;
    }

    render() {
        const {
            className = "",
            reactTable,
            showFilterInfo = false,
            filterable = true,
            showPagination = true,
            showPageJump = false,
            noDataText,
            pageSizeOptions = DEFAULT_PAGE_OPTIONS,
            totalCount = 0,
            showColumnSettings = true,
            columns = [],
            columnVisibility = {},
            columnDefs = [],
            loading,
            actionCell,
            autoApplyFilter,
            onApplyFilter,
            onFilteredChange,
            onPageChange,
            ...otherProps
        } = this.props;

        const tableColumns = this.createTableColumns(columns);

        return (
            <ReactTable
                onPageChange={this.onPageChange}
                columns={tableColumns}
                loading={loading}
                filtered={this.state.filtered}
                getTheadProps={() => ({
                    style: {
                        paddingRight: scrollBarWidth,
                    },
                })}
                getTheadFilterProps={() => ({
                    style: {
                        paddingRight: scrollBarWidth,
                    },
                })}
                getTheadFilterThProps={(
                    state: FinalState,
                    rowInfo: RowInfo,
                    column: TColumn,
                    instance: any,
                ) => {
                    const sortable =
                        column.sortable === undefined ? state.sortable : column.sortable;

                    const toggleSort = (
                        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
                    ) => {
                        if (!sortable) return;

                        instance.sortColumn(column, state.multiSort ? e.shiftKey : false);
                    };

                    const getColumnSortDirection = () => {
                        const sorted = state.sorted.find(
                            sortedColumn => sortedColumn.id === column.id,
                        );

                        if (!sorted) return null;

                        return sorted.desc ? "desc" : "asc";
                    };
                    return {
                        HeaderComponent: column.Header,
                        headerProps: state.getTheadThProps(state, undefined, column),
                        toggleSort,
                        sortable,
                        testid: column.testid,
                        columnSortDirection: getColumnSortDirection(),
                    };
                }}
                getTrProps={(state: FinalState, rowInfo: RowInfo) => {
                    const isHighlighted = this.props.isRowHighlighted?.(rowInfo.row);

                    const onClick = () => this.props.onRowClick?.(rowInfo.row);

                    const onHover = () => {
                        if (!isHighlighted) {
                            this.props.onRowHover?.(rowInfo.row);
                        }
                    };

                    const clearHover = () => {
                        if (isHighlighted) {
                            this.props.clearHover?.();
                        }
                    };

                    return {
                        isHighlighted,
                        onClick: this.props.onRowClick && onClick,
                        onMouseEnter: this.props.onRowHover && onHover,
                        onMouseLeave: this.props.clearHover && clearHover,
                    };
                }}
                NextComponent={Next}
                PreviousComponent={Previous}
                renderPageSizeOptions={StlTablePageSize}
                NoDataComponent={() =>
                    !!noDataText &&
                    !loading && (
                        <div className="rt-noData table-filter-empty-result">{noDataText}</div>
                    )
                }
                ref={reactTable}
                loadingText=""
                onSortedChange={this.onSortedChange}
                onFilteredChange={this.onFilteredChange}
                pageSizeOptions={pageSizeOptions}
                filterable={filterable}
                showPagination={showPagination}
                showPageJump={showPageJump}
                {...otherProps}
            >
                {(state: FinalState, makeTable: () => React.ReactElement) => {
                    const defaultColumns = columnDefs
                        .filter(col => col.id !== actionColumnId && col.hidable !== false)
                        .map(col => ({
                            name: col.id,
                            label: col.label,
                            hidable: col.hidable,
                            shouldAddSeparator: !!col.shouldAddSeparator,
                            showByDefault: col.showByDefault !== false,
                        }));

                    const defaultColumnVisibility = defaultColumns.reduce(
                        (visibilityMap, column) => {
                            visibilityMap[column.name as string] = column.showByDefault;
                            return visibilityMap;
                        },
                        {} as TColumnVisibility,
                    );

                    return (
                        <div
                            className={classnames("stl-table", className, {
                                "show-filter-info": showFilterInfo,
                            })}
                        >
                            <div className="sr-only" aria-live="polite">
                                {this.state.sortingNotification}
                            </div>
                            {(showFilterInfo || showColumnSettings) && (
                                <div className="stl-table-header-toolbar">
                                    {showFilterInfo && (
                                        <FilterInfoBanner
                                            filtered={this.props.filtered || state.filtered}
                                            sortedData={state.sortedData}
                                            getSortedData={this.props.getSortedData}
                                            data={state.data}
                                            totalCount={totalCount}
                                            clearAllFilters={
                                                this.props.clearAllFilters || this.clearAllFilters
                                            }
                                        />
                                    )}
                                    {this.props.toolbarComponent}
                                    {showColumnSettings && (
                                        <StlColumnSettings
                                            columnDefs={defaultColumns}
                                            columnVisibility={{
                                                ...defaultColumnVisibility,
                                                ...columnVisibility,
                                            }}
                                            mandatoryColumns={this.props.mandatoryColumns}
                                            onSetColumns={this.props.onSetColumns}
                                        />
                                    )}
                                </div>
                            )}
                            {makeTable()}
                        </div>
                    );
                }}
            </ReactTable>
        );
    }
}

export const StlTable = withScreenDensity(_StlTable);

export const getNewPreselectedFilters = (
    currentFilters: Array<Filter>,
    { filtered, id }: { filtered: Array<Filter>; id: string },
) => {
    const filtersWithoutUpdatedColumn = currentFilters.filter(filter => filter.id !== id);
    if (filtered.length) {
        return [...filtersWithoutUpdatedColumn, ...filtered];
    } else {
        return filtersWithoutUpdatedColumn;
    }
};

export const setColumnVisibility = (column: TColumn, columnVisibility: TColumnVisibility) => {
    const preferencesShow = columnVisibility[column.id as string];
    const show = preferencesShow !== undefined ? preferencesShow : column.showByDefault;

    return {
        ...column,
        show,
        filterable: show, // disable filtering by column if column is not visible
    };
};
