import { Suspense, useMemo } from "react";
import { Redirect, Switch } from "react-router-dom";
import classNames from "classnames";
import { PRODUCT_DETAILS } from "@app/auth/auth.constants";
import { ProtectedRoute } from "@app/auth/protectedRoute/protectedRoute";
import { StlStatus404Page } from "@app/auth/statuses";
import { StlNavigation } from "@app/bigBang/layout/navigation/navigation";
import {
    getCurrentUser,
    getProducts,
    getRoutePermissions,
    getUserRedirectPath,
} from "@app/store/currentUser/currentUser.selector";
import { useAppSelector } from "@app/store/hooks";
import { getApplicationPreferences } from "@app/store/userPreferences/userPreferences.selector";
import { StlLoader } from "@common/components";
import { Header } from "@common/components/headerLayout/header";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { TPlatformType } from "@root/src/common/components/headerLayout/platformSwitcher/platformSwitcher";
import { getBigBangRoutes } from "./bigBangLayout.routing";
import "./bigBangLayout.less";

export const BigBangLayout = () => {
    const { isNavigationMenuOpen } = useAppSelector(getApplicationPreferences);
    const currentUser = useAppSelector(getCurrentUser);
    const products = useAppSelector(getProducts);
    const defaultPath = useAppSelector(getUserRedirectPath);
    const routePermissions = useAppSelector(getRoutePermissions);
    const isOpenNavigationMenuAllowed = useMediaQuery("(min-width: 968px)");

    const routes = getBigBangRoutes(routePermissions);

    const isMenuOpen = isNavigationMenuOpen && isOpenNavigationMenuAllowed;

    const tabList = useMemo(() => routes.filter(routeData => routeData.icon), [routes]);

    const availablePlatformTypes: TPlatformType[] = products.map(
        (product: {
            product_type: string;
            product_name: string;
            icon: IconDefinition;
            subscriptions_expired: boolean;
            product_url: string;
        }) => {
            const productType = product.product_type as keyof typeof PRODUCT_DETAILS;
            return {
                value: productType,
                label: product.product_name,
                icon: PRODUCT_DETAILS[productType].image,
                subscriptions_expired: product.subscriptions_expired,
                product_url: product.product_url,
            };
        },
    );

    return (
        <div className="stl-big-bang">
            <Header
                currentUser={currentUser}
                availablePlatformTypes={availablePlatformTypes}
                logoUrl="/img/main-app-logo-insight.png"
            />
            <div className={classNames("content", !isMenuOpen && "closed-menu")}>
                <StlNavigation tabList={tabList} isNavigationMenuOpen={isMenuOpen} />
                <main className="main" role="main">
                    <Suspense fallback={<StlLoader show size="large" />}>
                        <Switch>
                            {routes
                                .filter(route => !!route.component)
                                .map(route => (
                                    <ProtectedRoute key={route.id} {...route} />
                                ))}
                            <Redirect exact path="/" to={defaultPath} />
                            <StlStatus404Page />
                        </Switch>
                    </Suspense>
                </main>
            </div>
        </div>
    );
};
