import { ReactNode, useMemo } from "react";
import { getIsEmulating, getProducts } from "@app/store/currentUser/currentUser.selector";
import { TCurrentUser } from "@app/store/currentUser/currentUser.types";
import { useAppSelector } from "@app/store/hooks";
import { StlAppHeader, StlButton } from "@common/components";
import { EmulationBadge } from "@common/components/headerLayout/emulationBadge/emulationBadge";
import {
    PlatformSwitcher,
    TPlatformType,
} from "@common/components/headerLayout/platformSwitcher/platformSwitcher";
import { UserInfoBadge } from "@common/components/headerLayout/userInfoBadge/userInfoBadge";
import { faBullhorn, faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header.less";

type TProps<U extends TCurrentUser, T extends TPlatformType> = {
    currentUser: U;
    children?: ReactNode;
    showHelpButton?: boolean;
    showWhatsNewButton?: boolean;
    availablePlatformTypes: T[];
    logoUrl: string;
};

export const Header = <U extends TCurrentUser, T extends TPlatformType>({
    children,
    currentUser,
    showHelpButton = true,
    showWhatsNewButton = true,
    availablePlatformTypes,
    logoUrl,
}: TProps<U, T>) => {
    const products = useAppSelector(getProducts);
    const isEmulating = useAppSelector(getIsEmulating);

    const userInfoContainer = useMemo(() => {
        return (
            <div className="user-info-container">
                <div className="left-bottom-banner">
                    {showWhatsNewButton && (
                        <StlButton
                            id="whats-new-button"
                            className="header-btn whats-new-btn"
                            startIcon={<FontAwesomeIcon icon={faBullhorn} />}
                            title="See what’s new!"
                            aria-label="See what’s new!"
                            size="sm"
                        >
                            See what’s new!
                        </StlButton>
                    )}
                    {showHelpButton && (
                        <StlButton
                            id="help-actions-menuControl"
                            className="header-btn help-btn"
                            startIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
                            title="Help"
                            ariaLabel="Help"
                            aria-hidden="true"
                            tabIndex={-1}
                            size="sm"
                        >
                            Help
                        </StlButton>
                    )}
                    {products.length > 1 && (
                        <PlatformSwitcher availablePlatformTypes={availablePlatformTypes} />
                    )}
                    <UserInfoBadge
                        userInfo={currentUser.user}
                        canChangeOrg={currentUser.canChangePlanningOrg}
                    />
                    {isEmulating && <EmulationBadge currentUser={currentUser} />}
                </div>
            </div>
        );
    }, [
        availablePlatformTypes,
        products.length,
        showHelpButton,
        showWhatsNewButton,
        currentUser,
        isEmulating,
    ]);

    return (
        <StlAppHeader logoUrl={logoUrl}>
            {children}
            {userInfoContainer}
        </StlAppHeader>
    );
};
