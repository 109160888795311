import React, { useMemo } from "react";
import { components, ContainerProps, SelectComponentsConfig } from "react-select";
import type { IndicatorProps } from "react-select/src/components/indicators";
import type { InputProps } from "react-select/src/components/Input";
import type { MenuProps } from "react-select/src/components/Menu";
import type {
    MultiValueProps,
    MultiValueRemoveProps,
} from "react-select/src/components/MultiValue";
import type { OptionProps } from "react-select/src/components/Option";
import type { GroupTypeBase, OptionTypeBase } from "react-select/src/types";
import { ANY_FILTER_OPTION } from "@common/constants/filters.constants";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TSelectProps = {
    required?: boolean;
    invalid?: boolean;
};

//Don't pass {} as default for customComponents: INST-21745, INST-24054
export const useSelectComponents = (
    { required, invalid }: TSelectProps,
    customComponents: SelectComponentsConfig<OptionTypeBase, boolean> | undefined,
) => {
    const menuListId = useMemo(() => Math.random(), []);

    return useMemo(
        () => ({
            SelectContainer: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: ContainerProps<OptionType, IsMulti, GroupType>,
            ) => {
                const newProps = {
                    ...props,
                    innerProps: {
                        ...props.innerProps,
                        role: "combobox",
                        "aria-expanded": props.selectProps.menuIsOpen,
                        "aria-haspopup": "listbox",
                        "aria-owns": menuListId,
                    },
                };

                return <components.SelectContainer {...newProps} />;
            },
            Menu: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: MenuProps<OptionType, IsMulti, GroupType>,
            ) => {
                const newProps = {
                    ...props,
                    innerProps: {
                        ...props.innerProps,
                        id: menuListId,
                        role: "listbox",
                        "data-testid": "menu",
                    },
                };

                return <components.Menu {...newProps} />;
            },
            Option: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: OptionProps<OptionType, IsMulti, GroupType>,
            ) => {
                const newProps = {
                    ...props,
                    innerProps: {
                        ...props.innerProps,
                        role: "option",
                        "aria-selected": props.isSelected,
                        "data-testid": "option",
                    },
                };

                return <components.Option {...newProps} />;
            },
            MultiValue: <
                OptionType extends OptionTypeBase,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: MultiValueProps<OptionType, GroupType>,
            ) => {
                const _value = props.getValue();

                const hideRemoveButton =
                    _value.length === 1 && _value[0].value === ANY_FILTER_OPTION.value;

                const data = { ...props.data, hideRemoveButton };

                return <components.MultiValue {...props} data={data} />;
            },
            MultiValueRemove: <
                OptionType extends OptionTypeBase,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: MultiValueRemoveProps<OptionType, GroupType>,
            ) => {
                if (props.data.hideRemoveButton) return null;

                return <components.MultiValueRemove {...props} />;
            },
            ClearIndicator: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: IndicatorProps<OptionType, IsMulti, GroupType>,
            ) => {
                const _value = props.getValue();
                const innerProps = {
                    ...props.innerProps,
                    "data-testid": "clearIndicator",
                };

                if (_value.length === 1 && _value[0].value === ANY_FILTER_OPTION.value)
                    return null;

                return <components.ClearIndicator {...props} innerProps={innerProps} />;
            },
            DropdownIndicator: <
                OptionType extends OptionTypeBase,
                IsMulti extends boolean,
                GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
            >(
                props: IndicatorProps<OptionType, IsMulti, GroupType>,
            ) => {
                return (
                    <div style={props.getStyles("dropdownIndicator", props)}>
                        <FontAwesomeIcon icon={faChevronDown} data-testid="dropdownIndicator" />
                    </div>
                );
            },
            Input: (props: InputProps) => {
                const newProps = {
                    ...props,
                    required,
                    role: "combobox",
                    "aria-required": required,
                    "aria-invalid": invalid,
                    "aria-autocomplete": "list",
                    "aria-expanded": (props as any).selectProps.menuIsOpen,
                    "aria-haspopup": "true",
                };

                return <components.Input {...newProps} />;
            },
            ...customComponents,
        }),
        [required, invalid, menuListId, customComponents],
    );
};
